import React from "react";

import photo from "../assets/products/1-1.png";

export const White = [
    {
        id: 5,
        name: "Aznaura",
        photo: photo,
        cover: false,
        cardDetails: "White, Dry",
        details: "White, Dry, Vintage: 2019",
        varieties: "Kisi  100%",
        alcohol: "12.3%",
        origin: "Mukuzani, Gurjaani, Kakheti region, East Georgia",
        fermentation: "Naturally fermented by traditional Georgian way, maceration goes with the grape skins.",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                Kisi - Georgian white grape vine variety. Distributed in Eastern Georgia, it also belongs to the local Kakheti vine variety group. This variety is native to Georgia.
            </p>
            <p className="product_text_desc">
                Dry white wine Aznaura made form Kisi grape variety fascinates with its incomparable properties and is very popular among lovers of quiet white and exotic wines. Savouring this luxurious Aznaura wine, his admirer is imbued with the infinity of the Kakhetian sultry sky and the wealth of the generous land. From the very beginning, Special attention is paid to the selection of the best bunches of grapes during the harvest that are, in the traditional spirit, handpicked. To avoid damaging them, they are transferred to the winery in special boxes.
            </p>
            <p>
                This luxurious light lemon Aznaura wine with its subtle aromas of green apple, green pear, apricot and meadow flowers vividly conveys the Kakhetian spirit. Medium crispy acidity blends harmoniously with the light body, and the medium velvety finish makes the wine even more intriguing.
            </p>
        </div>,
        quote: "Feel the gorgeous Georgian aura with a glass of Aznaura"
    },
]