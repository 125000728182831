import React from "react";
import logo from "../../assets/brand_bg.svg"
import heading from "../../assets/wines-of-georgia.svg"

import "./Slider.scss"

function Slider() : React.ReactNode {
    return (
        <div className="slider_content">
            <img className="brand_logo" src={logo} alt="Sommelier Selection"/>
            <img className="brand_heading" src={heading} alt="Wines of Georgia"/>
        </div>
    )
}


export default Slider;