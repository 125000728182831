import React from "react";

import kvevri from "../assets/kvevri.png";

export const Data = [
    {
        id: 1,
        question: "What grape varieties are made the Sommelier Selection Wines from?",
        desc: <div className="accordion-content">
            They are Georgian vine varieties like Saperavi, Rkatsiteli, Mtsvane, Qisi and others. They all are endemic to Georgia (local) vine varieties and have been harvested in Georgia for centuries.
        </div>
    },
    {
        id: 2,
        question: "Where does the Sommelier Selection wine live?",
        desc: <div className="accordion-content">
            <span className="block mb-2.5">Kvevri- Here lives the wine!</span>
            <img className="opacity-100" src={kvevri} alt="kvevri"/>
            Ceramic Kvevri is similar to large, egg-shaped amphorae without handles, Kvevri are large earthenware ceramic vessels used for the fermentation, storage and ageing of traditional Georgian wines. They are either buried below ground or set into the floors of large wine cellars. Kvevris vary in size, from 200liter to 2600 liters in volume up to 10000 liters.
            The process of making wine in Kvevri involves crashing the grapes and then pouring the juice, grape skins and pips into the Kvevri, which is then sealed within the week or so of initial fermentation.
        </div>
    },
    {
        id: 3,
        question: "What is unique in Kvevri Georgian technology?",
        desc: <div className="accordion-content">
            During wine fermentation in Kvevri, its ceramic walls allow micro-oxygenation from the surrounding soil; (in the ground), Kvevri keeps constant and low temperature. Micro-oxygenation and constant low temperature supports gradual and comprehensive fermentation process, no grapes’ sugar residues left in wine by the end of it. That’s why Kvevri wine is dry, full of healthy antioxidants and resveratrol, which was moving to the wine from the grapes’ skin during fermentation.
            Fermentation in Kvevri goes with the help of natural fermentation yeast and bacteria, which comes in with grapes, from vineyard. No fermentation yeast have been added artificially to make Sommelier Selection’s wines.
        </div>
    },
    {
        id: 4,
        question: "What does ‘GAUMARJOS’ Mean?",
        desc: <div className="accordion-content">
            GAUMARJOS is Georgian word, people say it when drink wine, like “cheers, “nazdravie”, “saluti”.
            GAUMARJOS literally, direct translation is - I wish you to become the winner.
        </div>,
    },
    {
        id: 5,
        question: "What does it mean, selection starts from vineyards?",
        desc: <div className="accordion-content">
            Quality of wine depends on quality of grapes. Sommelier Selection Wines are made in Kvevri cellars, which is close proximity to vineyards. Vineyards are located on special soil types, have minimum exposure to phytochemicals, which protects grapes from contamination and keeps surrounding natural microflora alive.
        </div>
    },
    {
        id: 6,
        question: "Why is SAPERAVI so special grape variety?",
        desc: <div className="accordion-content">
            Teinturier (French: to dye or to stain) grapes - flesh and juice is red in color due to anthocyanin pigments accumulating within the pulp of the grape berry itself. In most cases, anthocyanin pigments are limited to the outer skin tissue only, and the squeezed grape juice of most dark-skinned grape varieties is clear. The red color of red wine comes from anthocyanins extracted from the macerated (crushed) skins, over a period of days during the fermentation process.
            Unlike to other red vine varieties, SAPERAVI Vine varieties It is a teinturier grape, containing the red pigment anthocyanin, within the grape pulp as well as the grape skin and is unusual in being one of very few grapes used in single-varietal winemaking.
        </div>
    },
    {
        id: 7,
        question: "What does Amber wine have to offer? ",
        desc: <div className="accordion-content">
            Traditionally in Georgia, during maceration and fermentation of white wine, grape juice has contact with grape skin. Georgian wines made from white grape varieties, have darker color than usual ones. Amber wine color intensity depends on grape skin contact duration during the maceration.
            Grapes we use for wine making are fresh, not damaged, same day harvested, with alive natural fermentation yeast and useful bacteria coming with them, which is essential for natural fermentation process smooth and immediate start.
        </div>
    },
    {
        id: 8,
        question: "How long is wine usually fermented in Kvevri?",
        desc: <div className="accordion-content">
            The grape juice with grape skins fermented into the wine for at least five to six months in Kvevri, then wine is decanted and stored for further ageing in another Kvevri, before it will be bottled.
        </div>
    },
    {
        id: 9,
        question: "How does wine is filtered?",
        desc: <div className="accordion-content">
            Kvervi’s ceramic walls work as natural filtration units, adsorbing sediments and clarifying wine. Kvevri’s “egg shape” facilitates natural sedimentation/ facilitation- sediments goes directly to lowest narrow part. By the end of fermentation process, on the top of Kvevri, wine is crystal clear, with natural tastes and aromas have been well-preserved in.
        </div>
    },
    {
        id: 10,
        question: "What is it Wine Making Georgian village technology?",
        desc: <div className="accordion-content">
            Wine making Georgian village technology is similar to Kvevri process, natural fermentation process goes with skins and pulps, in large barrels. It undergoes 2-3 times natural sedimentation process to remove residue sediment. This process also protects all natural taste and aromas in wine.
        </div>
    },
    {
        id: 11,
        question: "Why wine making in Kvevri is a magic process?",
        desc: <div className="accordion-content">
            Everything in this process is logical and explainable, as it was mentioned above, but still not everything is clear. From the side it looks like the magic. Crashed grapes goes into the Kvevri, no fermentation additives added and in few months you’ll get delicious crystal-clear natural wine.
        </div>
    },
    {
        id: 12,
        question: "Why are Sommelier Selections’ wines so natural and exceptional?",
        desc: <div className="accordion-content">
            The vineyards’ locations, selection of fresh and non-damaged, same day harvested grapes for wine making; natural, low grade, prolonged fermentation process in Kvevri, natural sedimentation/ filtration, winemakers’ kindness and care makes Sommelier selection wines so special, natural, healthy, with full body of tastes and aromas in it.
        </div>
    },
    {
        id: 6767,
        question: "What does Sommelier Selection logo stand for?",
        desc: <div className="accordion-content">
            At first, attentive eye can see the idea comes from Newton’s Cradle. Newton’s Cradle aptly demonstrates the principle of the conservation of momentum (mass times speed). Metal balls are replaced by Georgian winemaking attributes. Figurately speaking, Sommelier Selection Wines are favoured because of its balanced attitude to winemaking process. From select grape harvesting to bottling, the whole collisions of energies and momentum results in special liquid.
        </div>
    },
    {
        id: 787,
        question: "Is Georgia homeland of Toasting as well?",
        desc: <div className="accordion-content">
            Visiting Georgia offers lots of different experiences which is unique and endemic. As we already know Georgia has history of 8,000 years of winemaking, also one from many unique traditions, Toasting has to be mentioned.
            It is believed the tradition of toasting hails back to ancient Georgia as well. The term “Sadghegrzelo” is a compound of the words dghe (day) and ghrdzeli (long). In this form, Sadghegrzelo means ‘to a long life,’ a wish that is expressed in the form of a toast.
            Toasts are told on “SUPRA” by “Tamada”. When “Tamada” finishes his speech, other members of SUPRA to be part of the idea Tamada usually say out loud: “GAUMARJOS”.
        </div>
    },
    {
        id: 7323,
        question: "What does SUFRA mean in Georgian Culture?",
        desc: <div className="accordion-content">
            SUPRA is a traditional Georgian feast and a part of Georgian social culture. There are two types of Supra: a festive supra, called a Keipi, and a Sombre Supra called a Kelekhi, that is always held after burials.
            The traditions of Supra, as an important part of Georgian social culture, were inscribed on the Intangible Cultural Heritage of Georgia list in 2017. Regardless of size and type, a supra is always led by a TAMADA. Eating is entirely appropriate during toasts, but talking is frowned upon. Once everyone who wishes to speak on the theme has done so, the Tamada proposes a new toast, and the cycle begins again.
        </div>
    },
    {
        id: 5562,
        question: "What does TAMADA mean?",
        desc: <div className="accordion-content">
            It is believed that the tradition of toasting hails back to ancient Georgia. Tamada is a Georgian toastmaster at a Georgian Supra (feast) or at a wedding. The discovery of a “bronze tamada”, or “toastmaster”, places the practice back to 500-700 BC.  So, Georgians, not only were oldest winemakers, but Tamada have been telling first toasts with wine in Georgia in ancient times.
            Tamada’s are still an important facet of modern Georgian gatherings. The Tamadas’ role is like the ‘hype guy’ of the feast. A person who plays the role of Tamada should possess exceptional oratory skills and the ability to read the audience. Their primary goal is to create shared experiences with captivating toast speeches.
            <div>
                So, <span>GAUMARJOS my friend!-Let’s become the winner with Sommelier Selection Wines</span> of Georgia!
            </div>
        </div>
    },

]