import React from "react";
import SimpleBar from 'simplebar-react';
import AboutCard from "../../components/AboutCard/AboutCard";
import icon1 from "../../assets/grapes.svg"
import photo1 from "../../assets/grapes.png"
import icon2 from "../../assets/vintaged.svg"
import photo2 from "../../assets/vintaged.png"
import icon3 from "../../assets/tested.svg"
import photo3 from "../../assets/tested.png"
import icon4 from "../../assets/saluted.svg"
import photo4 from "../../assets/saluted.png"

import ScreenSize from "../../helpers/ScreenSize";

import "./About.scss"

const aboutCards = [
    {
        id: 1,
        heading: "Created",
        subheading: "by magic of nature",
        icon: icon1,
        photo: photo1,

    },
    {
        id: 2,
        heading: "Vintaged",
        subheading:  <span className="about_card_content__subheading">by <br/> songs</span>,
        icon: icon2,
        photo: photo2,
    },
    {
        id: 3,
        heading: "Tested",
        subheading: "by wisdom",
        icon: icon3,
        photo: photo3,
    },
    {
        id: 4,
        heading: "Saluted",
        subheading: "to friendship",
        icon: icon4,
        photo: photo4,
    }
]

function About() : React.ReactNode {
    const {isDesktop} = ScreenSize();
    const cards = aboutCards.map(( card) =>
        <AboutCard key={card.id} heading={card.heading} subheading={card.subheading} icon={card.icon} photo={card.photo} ></AboutCard>
    )

    return(
        <div className="about_section">
            <div className="section_container about_container">
                <p className="about_description">
                    Selections we start from the start, from vineyards… Only the most select grapes are used to produce the wine. The quality of grapes is carefully monitored and harvested under specific conditions. Each step in the production is controlled to produce excellent wines.
                </p>
            </div>
            {
                isDesktop ?
                    <div className="about_cards_section">
                        {cards}
                    </div>
                    :
                    <SimpleBar>
                        <div className="about_cards_section">
                            {cards}
                        </div>
                    </SimpleBar>
            }
        </div>
    )
}

export default About;