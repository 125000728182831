import React from "react";
// import React, { Component }  from 'react';
import './App.scss';

import Header from "../components/Header/Header"
import Slider from "./Slider/Slider";
import Motto from "./Motto/Motto";
import PlaySound from "./Music/Music";
import ScrollTop from "./ScrollTop/ScrollTop";
import About from "./About/About";
import Selection from "./Selection/Selection";
import Accordion from "./Accordion/Accordion";
import Contact from "./Contact/Contact";
import Footer from "../components/Footer/Footer";

import sliderBg from "../assets/slider_bg.png";
import mobileBg from "../assets/mobile_bg.png";
import ScreenSize from "../helpers/ScreenSize";

function App() {
    const {isDesktop} = ScreenSize();
  return (
    <div className="App">
        <div style={{backgroundImage: `url(${isDesktop ? sliderBg : mobileBg})`}} className="slider_bg">
            <Header/>
            <div className="section_container">
                <Slider/>
            </div>
            <Motto/>
            <PlaySound/>
            <ScrollTop/>
            <About/>
        </div>
        <Selection/>
        <Accordion/>
        <Contact/>
        <Footer/>
    </div>
  );
}

export default App;
