import React, {useState} from "react";
// import Sound from "react-sound"
import ReactHowler from 'react-howler'
import sprite from "../../assets/sprite.svg"
import music from "../../assets/music.mp3"
import "./Music.scss"

const PlaySound = () => {
    const [isPlaying, setIsPlaying] = useState(false)

    return(
        <div>
            <button className="action_btn music_btn" onClick={() => {
                setIsPlaying(!isPlaying)
            }}>{
                isPlaying ?
                    <svg className="icon volume-max">
                        <use xlinkHref={sprite + "#volume-max"}></use>
                    </svg> :
                    <svg className="icon volume-min">
                        <use xlinkHref={sprite + "#volume-min"}></use>
                    </svg>
                }
            </button>
           <ReactHowler
            src={music}
            volume={0.5}
            loop={true}
            mute={isPlaying ? false : true}
           />
        </div>
    )
}

export default PlaySound;