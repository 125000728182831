import photo1 from "../assets/products/chacha_1-1.png"
import photo2 from "../assets/products/chacha_3.png"

export const ChachaData = [
    {
        id: 9,
        photo: photo1,
        name: "Agoras’ Chacha",
        cover: true,
        cardDetails: "ALC. 40%",
        details: "ALC. 40%",
        alcohol: "40%",
        origin: "Kardenakhi Kakheti region, East Georgia",
        fermentation: "Made from grape varieties of East Georgia",
    },
    {
        id: 10,
        photo: photo2,
        name: "Chacha Gold",
        cover: true,
        cardDetails: "ALC. 40%",
        details: "ALC. 40%",
        alcohol: "40%",
        origin: "Kakheti Region; East Georgia",
        fermentation: "Made from grape varieties of east Georgia, Aged in Oak.",
    },
    {
        id: 23,
        photo: photo1,
        name: "Agoras’ Chacha",
        cover: true,
        cardDetails: "ALC. 40%",
        details: "ALC. 40%",
        alcohol: "40%",
        origin: "Kardenakhi Kakheti region, East Georgia",
        fermentation: "Made from grape varieties of East Georgia",
    },
    {
        id: 24,
        photo: photo2,
        name: "Chacha Gold",
        cover: true,
        cardDetails: "ALC. 40%",
        details: "ALC. 40%",
        alcohol: "40%",
        origin: "Kakheti Region; East Georgia",
        fermentation: "Made from grape varieties of east Georgia, Aged in Oak.",
    }
]