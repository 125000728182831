import React from "react";
import Tabs from "../../components/Tabs/Tabs";
import TabContent from "../../components/Tabs/TabContent";
import ScreenSize from "../../helpers/ScreenSize";

import RedWines from "../../components/Tabs/RedWines/Redwines";
import AmberWines from "../../components/Tabs/AmberWines/AmberWines";
import WhiteWines from "../../components/Tabs/WhiteWines/WhiteWines";
import Chacha from "../../components/Tabs/Chacha/Chacha";

import "./Selection.scss"

function Selection(): React.ReactNode{
    const {isDesktop} = ScreenSize();
    return (
        <div name="collection_items" className="selection_section">
            <Tabs>
                <TabContent title={`${isDesktop ? "Red Wine" : "Red"}`}>
                    <RedWines/>
                </TabContent>
                <TabContent title={`${isDesktop ? "Amber Wines" : "Amber"}`}>
                    <AmberWines/>
                </TabContent>
                <TabContent title={`${isDesktop ? "White Wine" : "White"}`}>
                    <WhiteWines/>
                </TabContent>
                <TabContent title="Chacha">
                    <Chacha/>
                </TabContent>
            </Tabs>
        </div>
    )
}

export default Selection;