import React from "react";

import "./ProductInfo.scss";
export interface Props {
    name: string;
    details: string;
    varieties: string;
    alcohol: string;
    origin: string;
    fermentation: string;
    description: React.ReactNode;
    quote: string;
    photo: any;
}

function ProductInfo(props: Props) : React.ReactNode {
    return (
        <div className="product_specifications">
            <div className="section_container">
                <div className="specifications_content">
                    <div className="product_image_section">
                        <img src={props.photo} alt="wine"/>
                    </div>
                    <div className="product_description">
                        <div className="product_description_header">
                            <div className="product_name">{props.name}</div>
                            <h4 className="product_details">{props.details}</h4>
                        </div>
                        <div className="product_text">
                            {
                                props.varieties ?
                                    <div className="product_text_block product_text_details">
                                        <h4 className="product_text_heading"><b>Vine Varieties</b></h4>
                                        <h5 className="product_text_subheading">{props.varieties}</h5>
                                    </div> :
                                    null
                            }
                            <div className="product_text_block product_text_details">
                                <h4 className="product_text_heading"><b>Alc:</b></h4>
                                <h5 className="product_text_subheading">{props.alcohol}</h5>
                            </div>
                            <div className="product_text_block product_text_details">
                                <h4 className="product_text_heading"><b>Vineyard origin:</b></h4>
                                <h5 className="product_text_subheading">{props.origin}</h5>
                            </div>
                            <div className="product_text_block product_text_details">
                                <h4 className="product_text_heading"><b>Fermentation process:</b></h4>
                                <h5 className="product_text_subheading">{props.fermentation}</h5>
                            </div>
                            {props.description}
                        </div>
                        {
                            props.quote ?
                                <p className="product_motto">
                                    {props.quote}
                                </p> :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ProductInfo;