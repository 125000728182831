import React from "react";
import sprite from "../../../assets/sprite.svg";
import { Link } from "react-scroll";

import "./Arrows.scss"
import ScreenSize from "../../../helpers/ScreenSize";
export interface Props {
    onClick: any;
}

export default function NextArrow(props):React.ReactNode {
    const {isDesktop} = ScreenSize();
    return (
        <Link to="selection" offset={isDesktop ? -100 : -80} onClick={props.onClick} type="button" data-role="none" className="arrow next-arrow" offset={-100} spy={true} smooth={true} duration={600}>
            <div className="btn_icon">
                <svg className="icon">
                    <use xlinkHref={sprite + "#chevron-right"}></use>
                </svg>
            </div>
        </Link>
    )
}