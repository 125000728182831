import React from "react";
import {Link } from "react-scroll";
import "./Menu.scss"


import sprite from "../../../assets/sprite.svg"

export interface Props {
    menu: boolean;
    handleMenu: any,
    handleLink: any,
}


function Menu(props: Props) : React.ReactNode {
    return(
        <div className="menu-block">
            <button className="hamburger_menu_btn" onClick={props.handleMenu}>
                {
                    props.menu ?
                        <svg className="icon close-icon">
                            <use xlinkHref={sprite + "#close"}></use>
                        </svg>
                        :
                        <svg className="icon menu-icon">
                            <use xlinkHref={sprite + "#hamburger-menu"}></use>
                        </svg>
                }
            </button>
            <div className={`menu_list ${props.menu ? "active" : ""}`}>
                <div className="menu_content">
                    <div className="menu_item">
                         <Link onClick={props.handleLink} to="collection_items" className="menu_item_link" offset={-80} spy={true} smooth={true} duration={500}>SELECTION</Link>
                    </div>
                    <div className="menu_item">
                        <Link onClick={props.handleLink} to="know_more" className="menu_item_link" offset={-80} spy={true} smooth={true} duration={500}>KNOW MORE</Link>
                    </div>
                    <div className="menu_item">
                         <Link onClick={props.handleLink} to="contact" className="menu_item_link" offset={-80} spy={true} smooth={true} duration={500}>CONTACT US</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Menu