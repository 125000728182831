import React from "react";
import photo1 from "../assets/products/5-1-3.png";
import photo2 from "../assets/products/2.png";
import photo3 from "../assets/products/3-1.png";
import photo4 from "../assets/products/2-1.png";

export const Red = [
    {
        id: 1,
        photo: photo1,
        name: "Kardeneli",
        cover: false,
        cardDetails: "Red, Dry Natural Wine",
        details: "Red, Dry Natural Wine, Vintage: 2021",
        varieties: "Saperavi 100%",
        alcohol: "14.1%",
        origin: "Kardeneli Saperavi vineyard is located in the Tsrapi \ Akhoebi Terroir (viniculture micro-zone), in the village of Kardenakhi, Gurjaani region, in Kakheti. East Georgia.",
        fermentation: "Naturally fermented by traditional Georgian way. Macerated on skin over 4 weeks. Then yang wine separated without using press, for further secondary fermentation. Bottled in 1 year.",
        description: <div className="product_text_block">
            <p className="product_text_desc"> What kind of wine is Kardeneli 2021?</p>
            
            <p className="product_text_desc">
                All Kardeneli red wines come from same vineyard, Vineyards Location: The Tsarapi/Akhoebi micro-zone is known for its best and special vineyard conditions. The land there is not irrigated, is a mixture of stony forest land, and has good drainage, highly fertile and highly mineralized; the grapes come therefore full-bodied and healthy. 
            </p>

            <p className="product_text_desc">
                Vineyard care - harvest: The vineyards of Kardeneli which is located in the tsarapi zone, belong to the family; 3 generations of a large family take care of vineyard with great kindness and knowledge; Plant protection products were used in moderation so as not to disturb the balance and harm the natural yeasts that come with the grapes and are important for starting fermentation. Care was primarily aimed at the quality of the grapes, and not at its quantity; therefore, the grapes also turn out full and healthy. Full bunches, high in sugary substances, rich in useful grape acids and antioxidant substances.
            </p>
            
            <p className="product_text_desc">
                Harvesting and winemaking: Harvest was at the beginning of September 2021, grapes were collected in dry weather, in separate boxes, so that it would not be damaged before fermentation. De-stemmed, freshly sorted grape were macerated in the winery, right at the vineyard within a few hours after the end of the harvest.
            </p>

            <p className="product_text_desc">
                Alcohol in wine 14.1% Vol is a fairly high value, which ensures the wine health and allows for further aging in bottles (5-10 years).
            </p>

            <p className="product_text_desc">
                Tasting and Drinking: The taste is better than the aroma. But aroma opens up gradually after some time slaying in glasses. Wait for some time to see how flavours will open in full strengths. Meanwhile you can keep drinking.
            </p>

            <p className="product_text_desc">
                Wine for your cellar:
            </p>

            <p className="product_text_desc">
                Limited quantity! A total of 2200 bottles were bottled. Store bottles in the wine cellar in a cool place, lying down; Quality cork provides 5-7 years of storage for further aging. Open every year 1-2 times and compare with previous years the changes / developments and enrichment that have taken place over the past period.
            </p>

            <p className="product_text_desc">
                Enjoy the moments with Kardeneli red wine! Wine KARDENELI vintage 2021 is a must have wine for happy and friendly parties!
            </p>

            <p className="product_text_desc">
                Whoever drinks it - cheers! Gaumarjos! 
            </p>

        
        </div>,
        quote: "Talk extensively about this ultra-premium wine at your next dinner party.",
    },
    {
        id: 2,
        photo: photo2,
        name: "Kardeneli",
        cover: true,
        cardDetails: "Red, Dry, Kvevri Natural Wine",
        details: "Red, Dry, Kvevri Natural Wine",
        varieties: "Saperavi 100%",
        alcohol: "13.6%",
        origin: "Kardenakhi Kakheti region, East Georgia",
        fermentation: "Naturally fermented and aged in Kvevri- ceramic egg shape vessels, buried in the ground of cellar",
        description: <div className="product_text_block">
            <p className="product_text_desc"><span>Saperavi</span> (Georgian; literally "paint, dye, give color") is an acidic, teinturier-type grape variety native to the country of Georgia, where it is used to make many of the region's most well-known wines.
                Saperavi grapes produce very deep red wines that are suitable for extended aging. It has the potential to produce high alcohol levels. It is by far the most dominant Georgian red grape. It is a teinturier grape, containing the red anthocyanin within the grape pulp as well as the skin and is unusual in being one of very few such grapes used in single-varietal winemaking.
            </p>
            <p className="product_text_desc">
                <span>Kardenakhi / Tsarapi</span> located on the right bank of the Alazani River, in the region of Kakheti; ideally situated 350-550 meters above sea level. The soil-forming rocks in the area resulting to soil is loamy to light loamy with clay and stony deposits.
                Traditionally, vineyards in this area are not artificially irrigated. This purposeful deprivation creates a higher quality grape with elevated sweetness, depth and the signature aromatics of this region’s varieties.
                Full-bodied and full-flavoured. Strong initial aromas of later followed by pleasant notes.
            </p>
            <p className="product_text_desc">
                Wine is unfiltered, alive. Sediments may occur, only sediments mechanical removal was performed.
            </p>
        </div>
    },
    {
        id: 3,
        photo: photo3,
        name: "Velise",
        cover: false,
        cardDetails: "Red, Dry Natural Wine",
        details: "Red, Dry Natural Wine, Vintage: 2020",
        varieties: "Saperavi 100%",
        alcohol: "14.1%",
        origin: "Akhoebi Microzone, Kardenakhi village, Kakheti region, East Georgia",
        fermentation: "Naturally fermented by traditional Georgian way, maceration goes with the grape skins.",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                <span>Saperavi</span> - (literally "paint, dye, give color") is an acidic,teinturier-type grape variety native to the country of Georgia, where it is used to make many of the region's most well-known wines.
            </p>
            <p className="product_text_desc">
                Velise Saperavi is a classic red wine made from the legendary grapes of the same name. Saperavi, a unique thick skinned, rich in tannin grape variety, glides into a noble mono-type classic red wine. Grapes are harvested in Kardenakhi, Kakheti region providing best climate conditions for exceptional Saperavi terroirs.
            </p>
            <p className="product_text_desc">
                Saperavi wine, characterized with dark ruby colour is rich of cherry and black pepper aromas. The velvety balance of tannins and acidity is followed by a long noble finishing.
            </p>
        </div>,
        quote: "Für Elise, or Für Velise, that is a question",
    },
    {
        id: 4,
        photo: photo4,
        name: "Aznaura",
        cover: false,
        cardDetails: "Red, Dry Wine",
        details: "Red, Dry Wine, Vintage: 2019",
        varieties: "Saperavi 100%",
        alcohol: "12.7 %",
        origin: "Mukuzani microzone, Gurjaani, Kakheti region, East Georgia",
        fermentation: "Naturally fermented by traditional Georgian way, maceration goes with the grape skins.",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                Saperavi is a dry red Georgian wine. Grapes are harvested Mukuzani village, Gurjaani, Kakheti.
            </p>
            <p className="product_text_desc">
                Mukuzani is distinct from the other wines made from the same grapes and it is considered by many to be the best of the Georgian red wines made from Saperavi. This micro zone is well known for producing one of the best Saperavi wine. Mukuzani grapes has history of winning 9 gold medals, 2 silver medals and 3 bronze medals in international competitions.
            </p>
            <p className="product_text_desc">
                Saperavi grapes harvest in Mukuzani has a deeper red color with a soft smoky scent of oak and berry. The taste begins dry but the oak and fruit flavors quickly come through. As a result of its longer aging, Aznaura has more complexity than the other wines made from Saperavi grapes. An impressive blend of classic Georgian winemaking tradition method gives Aznaura wine a good balance of freshness and mildness.
            </p>
            <p className="product_text_desc">
                The beauty of Aznaura red dry wine is expressed in the exceptional harmony of moderate-high acidity and mild tannins.
            </p>
        </div>,
        quote: "Feel the gorgeous Georgian aura with a glass of Aznaura"
    },
]