import React, { ReactElement, useState } from "react"
import Tablink from "./Tablink"

import "./Tabs.scss"

type Props = {
    children: ReactElement[]
}

const Tabs: React.FC<Props> = ({ children }) => {
    const [selectedTab, setSelectedTab] = useState(0)

    return (
        <>
            <div className="section_container">
                <ul className="tablinks_section">
                    {children.map((item, index) => (
                        <Tablink
                            className={`${selectedTab === index ? "active" : ""}`}
                            key={index}
                            title={item.props.title}
                            index={index}
                            setSelectedTab={setSelectedTab}
                        />
                    ))}
                </ul>
            </div>
            {children[selectedTab]}
        </>
    )
}

export default Tabs