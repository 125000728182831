import React, {useState} from "react";
import ContactForm from "../../components/ContactForm/ContactForm";

import contactBg from "../../assets/contact_bg.png"

import "./Contact.scss";

function Contact() : React.ReactNode {
    const [status, setStatus] = useState('')
    return (
        <div name="contact" style={{backgroundImage: `url(${contactBg})`}} className="contact_section">
            <div className="section_container">
                <div className="contact_content">
                    <div className="contact_content_section">
                        <div className="contact_brand_content">
                            <div className="contact_brand">
                                <span>
                                    GAUMARJOS
                                </span>
                            </div>
                            <div className="text_section">
                                <div className="text_content">
                                    <div className="text-heading">
                                        BECOME THE WINNER
                                    </div>
                                    <div className="text-subheading">
                                        Collect kindness spirit with every sip!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact_content_section">
                        <div className="contact_form_section">
                            <div className="contact_motto">
                                <div className="contact_heading">
                                    We are looking for retail distributors worldwide.
                                </div>
                                <div className="contact_subheading">
                                    Contact us
                                </div>
                            </div>
                            <ContactForm status={status} setStatus={setStatus}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;