import React from "react";
import sprite from "../../../assets/sprite.svg"
import { Link } from "react-scroll";

import ScreenSize from "../../../helpers/ScreenSize";

import "./Arrows.scss"

export interface Props {
    onClick: any;
}

export default function PrevArrow(props):React.ReactNode {
    const {isDesktop} = ScreenSize();
    return (
        <Link to="selection" offset={isDesktop ? -100 : -80} onClick={props.onClick} type="button" data-role="none" className="arrow prev-arrow" spy={true} smooth={true} duration={500}>
            <div className="btn_icon">
                <svg className="icon">
                    <use xlinkHref={sprite + "#chevron-right"}></use>
                </svg>
            </div>
        </Link>
    )
}
