import React, {useState, useEffect, useRef} from "react";
import ProductCard from "../../ProductCard/ProductCard";
import ProductInfo from "../../ProductInfo/ProductInfo";
import Slider from "react-slick";

import PrevArrow from "../../../App/Selection/Arrows/PrevArrow";
import NextArrow from "../../../App/Selection/Arrows/NextArrow";
import { Amber } from "../../../WebsiteData/Amber";

function AmberWines() : React.ReactNode {
    const [state, setState] = useState({ nav1: null, nav2: null });
    const slider1 = useRef();
    const slider2 = useRef();

    useEffect(() => {
        setState({
            nav1: slider1.current,
            nav2: slider2.current
        });
    }, []);

    const { nav1, nav2 } = state;

    const cards = Amber.map(wineCard =>
        <ProductCard cover={wineCard.cover} photo={wineCard.photo} key={wineCard.id} name={wineCard.name} cardDetails={wineCard.cardDetails}/>
    )

    const infoCards = Amber.map(wineInfo =>
        <ProductInfo cover={wineInfo.cover} photo={wineInfo.photo} key={wineInfo.id} name={wineInfo.name} details={wineInfo.details} varieties={wineInfo.varieties} alcohol={wineInfo.alcohol} origin={wineInfo.origin} fermentation={wineInfo.fermentation} description={wineInfo.description} quote={wineInfo.quote}/>
    )

    return (
        <div name="collection" className="product_cards">
            <div className="selection_collection">
                <Slider
                    slidesToShow={3}
                    centerMode={true}
                    className={"center"}
                    dots={true}
                    arrows={false}
                    asNavFor={nav1}
                    ref={slider => (slider2.current = slider)}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    responsive={[
                        {
                            breakpoint: 640,
                            settings: {
                                slidesToShow: 1,
                                centerMode: true,
                                infinite: true,
                                // centerPadding: 50,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                centerMode: true,
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                centerMode: true,
                                centerPadding: '80px',
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 1280,
                            settings: {
                                slidesToShow: 3,
                                centerMode: true,
                                centerPadding: '100px',
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 1536,
                            settings: {
                                slidesToShow: 3,
                                centerMode: true,
                                centerPadding: '100px',
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 1920,
                            settings: {
                                slidesToShow: 3,
                                centerMode: true,
                                centerPadding: '200px',
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 1930,
                            settings: {
                                slidesToShow: 3,
                                centerMode: true,
                                centerPadding: '250px',
                                infinite: true
                            }
                        }
                    ]}
                >
                    {cards}
                </Slider>
            </div>
            <div name="selection" className="collection_item">
                <Slider
                    asNavFor={nav2}
                    ref={slider => (slider1.current = slider)}
                    arrows={true}
                    prevArrow={<PrevArrow/>}
                    nextArrow={<NextArrow />}
                    adaptiveHeight={true}
                >
                    {infoCards}
                </Slider>
            </div>
        </div>
    )
}

export default AmberWines;