import React, {useState, useEffect} from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import brand from "../../assets/brand.svg";
import ScreenSize from "../../helpers/ScreenSize";

import Menu from "./Menu/Menu";
import "./Header.scss";

function Header() : React.ReactNode {
    const {isDesktop} = ScreenSize();

    const [menu, setMenu] = useState(false);

    useEffect(() => {
        const header = document.getElementById("header");

        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > 115) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, [])

    const handleMenu = () => {
        setMenu(prevState => !prevState);
    }

    const handleLink = () => {
        setMenu(false)
    }


    return (
        <div id="header" className="header_wrapper">
            <header className={`header ${menu ? "active" : ""}`}>
                <div className="section_container header_container">
                    <div className="header_content">
                        <div className="brand">
                            <div className="brand_link" onClick={() => scroll.scrollToTop()}>
                                <img src={brand} alt="sommelier-selection"/>
                            </div>
                        </div>
                        {
                            isDesktop ?
                                <nav className="header_nav">
                                    <div className="header_nav_element">
                                        <Link className="header_nav_link" to="collection_items" offset={-100} spy={true} smooth={true} duration={500}>SELECTION</Link>
                                    </div>
                                    <div className="header_nav_element">
                                        <Link className="header_nav_link" to="know_more" offset={-100} spy={true} smooth={true} duration={500}>KNOW MORE</Link>
                                    </div>
                                    <div className="header_nav_element">
                                        <Link className="header_nav_link" to="contact" offset={-100} spy={true} smooth={true} duration={500}>CONTACT US</Link>
                                    </div>
                                </nav> :
                                <Menu handleLink={handleLink} menu={menu} handleMenu={handleMenu} />
                        }
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header;