import React from "react";
import photo1 from "../assets/products/6-1.png";
import photo2 from "../assets/products/4-1.png";
import photo3 from "../assets/products/7.png";

export const Amber = [
    {
        id: 6,
        photo: photo1,
        name: "Kardeneli",
        cover: false,
        cardDetails: "Amber Dry Kvevri Natural wine",
        details: "Amber, Dry, Kvevri Natural Wine, Vintage: 2019",
        varieties: "Rkatsiteli 100%",
        alcohol: "12.5%",
        origin: "Tsarapi Microzone, Kardenakhi Village, Kakheti Region, East Georgia",
        fermentation: "Naturally fermented and aged in Kvevri-ceramic egg shape vessels, buried in the ground of cellar",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                Grapes for Kardeneli wine are harvested in Tsarapi micro zone - Kardenakhi vineyards, Gurjaani, Eastern Georgia
            </p>
            <p className="product_text_desc">
                In Georgia, clay vessels were found with seeds of Rkatsiteli grapes which date back to 3000 BC.
            </p>
            <p className="product_text_desc">
                Gurjaani is a town in Kakheti, a region in Eastern Georgia. It is located in the Alazani River Plain and is known for being the center of largest wine-making region of Georgia.
            </p>
            <p className="product_text_desc">
                <span>Kardeneli Rkatsiteli</span> is a traditional Georgian wine, made with an ancient winemaking method of fermentation in Kvevri – clay pots buried in ground. The wine stays in contact with the pulp for 7-8 months, gaining a unique golden amber colour and exceptional bouquet of ripe fruit and spices.
            </p>
            <p className="product_text_desc">
                This Kvevri wine represents a harmonious balance of mild tannins and a delicate full body, with a nice herby finishing.
            </p>
        </div>,
        quote: "Cardinal point of Pleasure from KARDENELI"
    },
    {
        id: 7,
        photo: photo2,
        name: "Velise",
        cover: false,
        cardDetails: "Amber, Dry, Kvevri Natural wine",
        details: "Amber, Dry, Kvevri Natural Wine, Vintage: 2019",
        varieties: "Rkatsiteli 100%",
        alcohol: "12.2%",
        origin: "Tsarapi Microzone, Kardenakhi Village, Kakheti Region, East Georgia",
        fermentation: "Naturally fermented and aged in Kvevri-ceramic egg shape vessels, buried in the ground of cellar.",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                <span>Rkatsiteli</span> (literally "red stem" or "red horned") is a kind of grape used to produce white wine.</p>
            <p className="product_text_desc">
                <span>Velise</span> is made with the traditional method of Georgian winemaking using clay vessel – Kvevri, buried in the ground. The wine is left in contact with the grape seeds and skins for 7-8 months until mid-April. The Kvevri enriches the wine with varied, vivid aromas and flavors, and the resulting wine is amber-yellow and full-bodied, with immediate notes of sweet spices reminiscent of panettone. The bouquet of this blessed wine lingers in the mouth for a long time; its flavor reveals soft tannins, and the wine has quite a long finish. Rkatsiteli Kvevri wine best served with sour food.
            </p>
        </div>,
        quote: "Rkatsiteli in need is a friend indeed.",
    },
    {
        id: 8,
        photo: photo3,
        name: "Aznaura",
        cover: true,
        cardDetails: "Amber, Dry Natural wine",
        details: "Amber, Dry Natural Wine",
        varieties: "Khikhvi 30% Kakhuri Rkatsiteli 70%",
        alcohol: "13.4%",
        origin: "Kardenakhi Kakheti region, East Georgia",
        fermentation: "Naturally fermented by traditional Georgian way, maceration",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                <span>Rkatsiteli</span> (literally translated "red stem" or "red horned") is a kind of grape used to produce white/ amber wine.
                This ancient vine variety originates in Georgia and is one of the oldest - endemic Vine variety in Georgia.
            </p>
            <p className="product_text_desc">
                <span>Kardenakhi/ Tsarapi</span> located on the right bank of the Alazani River, in the region of Kakheti; ideally situated 350-550 meters above sea level. The soil-forming rocks in the area resulting to soil is loamy to light loamy with clay and stony deposits.
                Traditionally, vineyards in this area are not artificially irrigated. This purposeful deprivation creates a higher quality grape with elevated sweetness, depth and the signature aromatics of this region’s varieties.
                Full-bodied and full-flavoured. Strong initial aromas of later followed by pleasant notes.
            </p>
            <p className="product_text_desc">
                The distinct amber and pink color of the Rkatsiteli grape, and the resulting amber to straw color of the wine, are also attributed to the perfection of the natural environment in this micro zone.
            </p>
            <p className="product_text_desc">
                Wine is unfiltered, alive. Sediments may occur, only sediments mechanical removal was performed.
            </p>
        </div>
    },
    {
        id: 66,
        photo: photo1,
        name: "Kardeneli",
        cover: false,
        cardDetails: "Amber Dry Kvevri Natural wine",
        details: "Amber, Dry, Kvevri Natural Wine, Vintage: 2019",
        varieties: "Rkatsiteli 100%",
        alcohol: "12.5%",
        origin: "Tsarapi Microzone, Kardenakhi Village, Kakheti Region, East Georgia",
        fermentation: "Naturally fermented and aged in Kvevri-ceramic egg shape vessels, buried in the ground of cellar",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                Grapes for Kardeneli wine are harvested in Tsarapi micro zone - Kardenakhi vineyards, Gurjaani, Eastern Georgia
            </p>
            <p className="product_text_desc">
                In Georgia, clay vessels were found with seeds of Rkatsiteli grapes which date back to 3000 BC.
            </p>
            <p className="product_text_desc">
                Gurjaani is a town in Kakheti, a region in Eastern Georgia. It is located in the Alazani River Plain and is known for being the center of largest wine-making region of Georgia.
            </p>
            <p className="product_text_desc">
                <span>Kardeneli Rkatsiteli</span> is a traditional Georgian wine, made with an ancient winemaking method of fermentation in Kvevri – clay pots buried in ground. The wine stays in contact with the pulp for 7-8 months, gaining a unique golden amber colour and exceptional bouquet of ripe fruit and spices.
            </p>
            <p className="product_text_desc">
                This Kvevri wine represents a harmonious balance of mild tannins and a delicate full body, with a nice herby finishing.
            </p>
        </div>,
        quote: "Cardinal point of Pleasure from KARDENELI"
    },
    {
        id: 73,
        photo: photo2,
        name: "Velise",
        cover: false,
        cardDetails: "Amber, Dry, Kvevri Natural wine",
        details: "Amber, Dry, Kvevri Natural Wine, Vintage: 2019",
        varieties: "Rkatsiteli 100%",
        alcohol: "12.2%",
        origin: "Tsarapi Microzone, Kardenakhi Village, Kakheti Region, East Georgia",
        fermentation: "Naturally fermented and aged in Kvevri-ceramic egg shape vessels, buried in the ground of cellar.",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                <span>Rkatsiteli</span> (literally "red stem" or "red horned") is a kind of grape used to produce white wine.</p>
            <p className="product_text_desc">
                <span>Velise</span> is made with the traditional method of Georgian winemaking using clay vessel – Kvevri, buried in the ground. The wine is left in contact with the grape seeds and skins for 7-8 months until mid-April. The Kvevri enriches the wine with varied, vivid aromas and flavors, and the resulting wine is amber-yellow and full-bodied, with immediate notes of sweet spices reminiscent of panettone. The bouquet of this blessed wine lingers in the mouth for a long time; its flavor reveals soft tannins, and the wine has quite a long finish. Rkatsiteli Kvevri wine best served with sour food.
            </p>
        </div>,
        quote: "Rkatsiteli in need is a friend indeed.",
    },
    {
        id: 98,
        photo: photo3,
        name: "Aznaura",
        cover: true,
        cardDetails: "Amber, Dry Natural wine",
        details: "Amber, Dry Natural Wine",
        varieties: "Khikhvi 30% Kakhuri Rkatsiteli 70%",
        alcohol: "13.4%",
        origin: "Kardenakhi Kakheti region, East Georgia",
        fermentation: "Naturally fermented by traditional Georgian way, maceration",
        description: <div className="product_text_block">
            <p className="product_text_desc">
                <span>Rkatsiteli</span> (literally translated "red stem" or "red horned") is a kind of grape used to produce white/ amber wine.
                This ancient vine variety originates in Georgia and is one of the oldest - endemic Vine variety in Georgia.
            </p>
            <p className="product_text_desc">
                <span>Kardenakhi/ Tsarapi</span> located on the right bank of the Alazani River, in the region of Kakheti; ideally situated 350-550 meters above sea level. The soil-forming rocks in the area resulting to soil is loamy to light loamy with clay and stony deposits.
                Traditionally, vineyards in this area are not artificially irrigated. This purposeful deprivation creates a higher quality grape with elevated sweetness, depth and the signature aromatics of this region’s varieties.
                Full-bodied and full-flavoured. Strong initial aromas of later followed by pleasant notes.
            </p>
            <p className="product_text_desc">
                The distinct amber and pink color of the Rkatsiteli grape, and the resulting amber to straw color of the wine, are also attributed to the perfection of the natural environment in this micro zone.
            </p>
            <p className="product_text_desc">
                Wine is unfiltered, alive. Sediments may occur, only sediments mechanical removal was performed.
            </p>
        </div>
    },
]