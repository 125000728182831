import React from "react";

import "./AboutCard.scss"

export interface Props {
    heading: string,
    subheading: React.ReactNode,
    icon: any,
    photo: any,
}


function AboutCard(props: Props) : React.ReactNode {
    return (
        <div  className="about_card">
            <div className="blend_effect">
                <img src={props.photo} alt="grapes"/>
            </div>
            <div className="about_card_content">
                <div className="heading_section">
                    <span className="about_card_content__heading">{props.heading}</span>
                </div>
                <span className="about_card_content__subheading">{props.subheading}</span>
                <img className="content_icon" src={props.icon} alt="grapes icon"/>
            </div>
        </div>
    )
}

export default AboutCard;