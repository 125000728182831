import React, {useCallback} from "react";

type Props = {
    title: string;
    className: string;
    index: number;
    setSelectedTab: (index: number) => void;
}

const Tablink: React.FC<Props> = ({ title, setSelectedTab, index, className }) => {

    const onClick = useCallback(() => {
        setSelectedTab(index)
    }, [setSelectedTab, index])

    return (
        <li className="tablink">
            <button className={`tablink_btn ${className}`} onClick={onClick}>
                <div>
                    {title}
                </div>
            </button>
        </li>
    )
}
export default Tablink;