import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ScreenSize from "../../helpers/ScreenSize";

import { Data } from "../../WebsiteData/FAQ";
import "./Accordion.scss"
import sprite from "../../assets/sprite.svg";
import background from "../../assets/faq_bg.png";

function Accordion() : React.ReactNode {
    const {isDesktop} = ScreenSize();

    const removeSelect = () => {
        let tab = document.getElementsByClassName('react-tabs__tab-panel--selected')
        if(tab[0] !== undefined) {
            tab[0].classList.remove('react-tabs__tab-panel--selected')
        }
    }
    const tabs = Data.map((item, index) => {
        return (
            <Tab key={index}>
                <h4>{item.question}</h4>
                <div className="tab_btn">
                    <svg className="icon">
                        <use xlinkHref={sprite + "#chevron-right"}></use>
                    </svg>
                </div>
            </Tab>
        );
    })
    const panels = Data.map((panel, index) => {
        return(
            <TabPanel key={index}>
                <div className="panel_text">
                    <h4>{panel.desc}</h4>
                </div>
            </TabPanel>
        )
    })
    const mobpanels = Data.map((panel, index) => {
        return(
            <TabPanel key={index} className="react-tabs__tab-panel--mobile">
                <div className="panel_question">
                    <div className="tab_btn" onClick={removeSelect}>
                        <svg className="icon">
                            <use xlinkHref={sprite + "#chevron-right"}></use>
                        </svg>
                    </div>
                    <h4>{panel.question}</h4>
                </div>
                <div className="panel_answer">
                    <h4>{panel.desc}</h4>
                </div>
            </TabPanel>
        )
    })
    return (
        <div name="know_more" style={{backgroundImage: `url(${background})`}} className="accordion_section">
            <div className="section_container accordion-container">
                <div className="section_heading">KNOW MORE</div>
                {isDesktop ?
                    <Tabs>
                        <TabList>
                            {tabs}
                        </TabList>
                        {panels}
                    </Tabs> :
                    <Tabs className="react-tabs__mobile" defaultIndex={-1}>
                        <TabList>
                            {tabs}
                        </TabList>
                        {mobpanels}
                    </Tabs>
                }
            </div>
        </div>
    );
}

export default Accordion;