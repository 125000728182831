import React from "react";
import "./ProductCard.scss";
import { Link } from "react-scroll";
import ScreenSize from "../../helpers/ScreenSize";

export interface Props {
    name: string;
    cardDetails: string;
    photo: any;
}



function ProductCard(props: Props) : React.ReactNode {
    const {isDesktop} = ScreenSize();
    return (
        <div className="product_card_section">
            <div className="product_card">
                <div className="product_card_image">
                    <img src={props.photo} alt="wine"/>
                </div>
                <div className="product_card_info">
                    <h2 className="product_name">{props.name}</h2>
                    <p className="description">{props.cardDetails}</p>
                    <Link to="selection" offset={isDesktop ? -100 : -80} className="button" spy={true} smooth={true} duration={600}>
                        <div>
                            See more
                        </div>
                    </Link>
                </div>
            </div>
        </div>
)
}

export default ProductCard;